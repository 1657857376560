import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

const DeleteAccount = () => {
	return (
		<Container>
			<Row className='justify-content-center'>
				<Col xxl={10} xl={11} xs={12}>
                    <div className='sparkiq-delete-account'>
                        <div className='siq-da-header'>
                            <div className='siq-da-logo'>
                                <img src="../logo/app-Icon.svg" className='img-fluid' alt=''/>
                                <p>Bank Balance Check Statement</p>
                            </div>
                        </div>
                        <div className='siq-da-body'>
                            <h1>How To Delete Your Account</h1>
                            <p>Users can delete their account from Bank Balance Check Statement App, once the account is deleted, it cannot be recovered. Not even if you delete it by mistake.</p>

                            <h2>Way To Delete your Bank Balance Check Statement App Account:</h2>
                            <ul>
                                <li>Open the Bank Balance Check Statement App.</li>
                                <li>Click User Icon Bottom on Right Hand Corner - Click on Delete Icon Top on Left Hand Corner - Delete Confirmation - Delete.</li>
                            </ul>

                            <h2>If you delete your account, the Following Data Will be Permanently Deleted and can't be Undo or Restored in Back:</h2>
                            <ul>
                                <li>Your account will be removed from the Bank Balance Check Statement App.</li>
                                <li>Once the account is deleted, all the bank and related information added by the user will be removed.</li>
                                <li>You may not be able to regain access to your account.</li>
                            </ul>
                        </div>
                    </div>
				</Col>
			</Row>
		</Container>
	)
}

export default DeleteAccount